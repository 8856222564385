<template>
    <div class="dateRangePicker" v-click-outside="() => { show = false }">
        <div class="selection" @click="show = !show">{{ dateFrom }} - {{ dateTo }}</div>
        <div class="popupRangePicker" v-show="show" :class="{ 'active' : show }">

            <h2>Select date range</h2>

            <div class="dateRangeContent">
                <div class="customList">
                    <button @click="setRangeToday">Today</button>
                    <button @click="setRangeYesterday">Yesterday</button>
                    <button @click="setRangeDays(7)">7 Days</button>
                    <button @click="setRangeDays(30)">30 Days</button>
                    <button @click="setRangeDays(365)">1 Year</button>
                </div>

                <div class="dateRangePickers">
                    <div class="input">
                        <div class="_header">
                            <label>From</label>
                        </div>
                        <DatePickerSimple position="left" v-model="dateFrom"/>
                    </div>

                    <div class="input">
                        <div class="_header">
                            <label>To</label>
                        </div>
                        <DatePickerSimple position="left" v-model="dateTo" :from="dateFrom"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment'
import DatePickerSimple from './DatePickerSimple.vue'
    export default {
        components: {
            DatePickerSimple
        },
        props: ['from', 'to'],
        data() {
            return {
                dateFrom: this.from || null,
                dateTo: this.to || null,
                show: false
            }
        },
        methods: {
            setRangeToday() {
                this.dateFrom = moment.utc().format("MM/DD/YYYY");
                this.dateTo = moment.utc().format("MM/DD/YYYY");
            },
            setRangeYesterday() {
                this.dateFrom = moment.utc().startOf('day').subtract(1, 'day').format("MM/DD/YYYY");
                this.dateTo = this.dateFrom;
            },
            setRangeDays(days) {
                this.dateFrom = moment.utc().startOf('day').subtract(days, 'days').format("MM/DD/YYYY");
                this.dateTo = moment.utc().format("MM/DD/YYYY");
            }
        },
        watch: {
            dateFrom() {
                this.$emit('update:from', this.dateFrom);
                this.show = false;
            },
            dateTo() {
                this.$emit('update:to', this.dateTo);
                this.show = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
.dateRangePicker {
    position: relative;
}

.selection {
    cursor: pointer;
}

.popupRangePicker {
    position: absolute;
    top: 0;
    right: 0;
    width: 350px;
    background: $bgSecondary;
    z-index: 1;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    display: grid;
    row-gap: 20px;
    padding: 20px;
    &.active {
        z-index: 999;
    }
}


.dateRangeContent {
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr);
    align-items: start;
    column-gap: 20px;
}

.dateRangePickers {
    display: grid;
    row-gap: 20px;
}

.customList {
    display: grid;
    row-gap: 5px;
    justify-items: start;
    padding: 0 10px 0 0;
    border-right: 1px solid $borderColor;
    button {
        height: 28px;
        border-radius: 14px;
        border: 1px solid $borderColor;
        background: transparent;
        text-align: left;
        padding: 0 10px;
        font-size: 12px;
        transition: ease 0.3s;
        &:hover {
            background: $buttonColor;
            color: $buttonText;
        }
    }
}
</style>